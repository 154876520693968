* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

/* intro */

.loading {
  animation: 3s load ease-in-out;
  animation-fill-mode: forwards;
  background-color: #fff;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* navbar */
/* Battery */
.battery {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 253, 253, 0.3);
  background-color: transparent;
  padding: 1px;
  height: 12px;
  width: 24px;
  border-radius: 3px;
}

.battery-charging {
  width: 100%;
  height: 100%;
}

.battery-level {
  background-color: white;
  height: 100%;
  border-radius: 2px;
}

.battery-cell {
  transform: translateX(100%);
  width: 4px;
  height: 5px;
  border-right: 1px solid rgba(255, 253, 253, 0.3);
  border-radius: 50%;
}

.battery-container > .subsection-left {
  margin-top: 8px;
}

.battery-procentaje {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* ------------------------------------------- */

/* wifi */

.wifi {
  height: 20px;
  width: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(3px);
  cursor: pointer;
}

.wifi-cell {
  border: 3px solid transparent;
  border-top-color: rgba(177, 177, 177, 0.3);
  border-radius: 50%;
  padding-top: 10px;
}

.wifi-line1,
.wifi-line2,
.wifi-line3 {
  border-top-color: rgba(133, 133, 133, 0.3);
  margin-bottom: 3px;
}

.wifi-line1 {
  width: 17%;
  border-radius: 30%;
  transform: translateY(-180%);
}

.wifi-line2 {
  width: 43%;
  border-radius: 40%;
  transform: translateY(-90%);
}

.wifi-line3 {
  width: 67%;
  border-radius: 50%;
}

.wifi-cell-active {
  border-top-color: white;
}

/* -------------------------------------------- */

/* notification */


@keyframes notification {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.notification {
  animation: notification ease-in-out 0.3s forwards;
}

@media screen and (max-width: 1024px) {
  @keyframes notification {
    0% {
      transform: translateY(-100%) translateX(50%);
      
    }
    100% {
      transform: translateY(1) ;
    }
  }
}

.dinotification {
  animation: dinotification ease-in-out 0.3s forwards;
}

@keyframes dinotification {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

